import {
  Box,
  Heading,
  SimpleGrid,
  Image,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Button,
  Icon,
  IconButton,
  Container,
  useToast,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { ImTicket } from "react-icons/im";
import { useEffect, useRef } from "react";
import { ethers } from "ethers";
import { gql, useQuery } from "@apollo/client";
import { CSVLink } from "react-csv";

// Provider
var provider, signer;
if(window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
}

// GQL Queries
const PURCHASE_ITEM = gql`
  mutation Mutation($walletAddress: ID!, $itemId: ID!, $signature: String) {
    purchaseItem(
      walletAddress: $walletAddress
      itemId: $itemId
      signature: $signature
    )
  }
`;

const PICK_WINNERS = gql`
  mutation Mutation($walletAddress: ID!, $itemId: ID!, $signature: String) {
    pickRaffleWinners(
      walletAddress: $walletAddress
      itemId: $itemId
      signature: $signature
    )
  }
`;

const GET_WINNERS = gql`
  query Winners($id: ID!) {
    allWinners(ID: $id) {
      winners {
        discordID
        walletAddress
        whitelistAddress
      }
      _id
      name
    }
  }
`;

const GET_ALL_ITEMS = gql`
  query allItems {
    allItems {
      _id
      name
      description
      image
      stock
      supply
      price
      saleStatus
      type
      expirationDate
    }
  }
`;

const GET_ROLE = gql`
  query User($id: ID!) {
    user(ID: $id) {
      role
      discordID
    }
  }
`;

function ShopItem(props) {
  const {
    id,
    name,
    image,
    price,
    supply,
    description,
    client,
    display,
    winners,
    type,
    expirationDate,
    refetchWinners,
    walletAddress,
    discordID
  } = props;
  const toast = useToast();
  const csvLink = useRef();
  const raffleCSVLink = useRef();

  const dateString = (expirationDate) => {
    const datetime = new Date(expirationDate);
    var hours = datetime.getHours();
    var minutes = datetime.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; 
    minutes = minutes < 10 ? '0'+minutes : minutes;
    return `${hours}:${minutes} ${ampm}, ${datetime.getMonth()+ 1}/${datetime.getDate()}/${datetime.getFullYear()}`;
  }

  const purchase = async () => {
    if(!discordID || !/\S/.test(discordID)) {
      toast({
        title: "Error",
        description: "Set your discord ID in Manage Account Tab to purchase items",
        status: "error",
        duration: 6000,
        isClosable: true,
      });

      return;
    }

    const address = await signer.getAddress();
    const signature = await signer.signMessage("Purchase item");
    
    const curDate = new Date();
    const expirationDateObj = new Date(expirationDate);
    if(expirationDate && curDate > expirationDateObj) {
      toast({
        title: "Error",
        description: "This Item has expired",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if(type === "raffle" && winners.length > 0) {
      toast({
        title: "Error",
        description: "Winners for this raffle have already been picked",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    let purchaseErrMessage = (
      await client.mutate({
        mutation: PURCHASE_ITEM,
        variables: {
          walletAddress: address,
          itemId: id,
          signature,
        },
      })
    ).data.purchaseItem;

    if (purchaseErrMessage === "") {
      toast({
        title: "Success",
        description: "Purchase Successful",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      const event = new Event("statsRefresh");
      document.dispatchEvent(event);
      const event2 = new Event("itemRefresh");
      document.dispatchEvent(event2);
    } else {
      toast({
        title: "Error",
        description: purchaseErrMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const pickRaffleWinners = async () => {
    const address = await signer.getAddress();
    const signature = await signer.signMessage(`Are you sure you want to pick the raffle winners for the item ${name}?`);
    if(winners.length > 0) {
      toast({
        title: "Error",
        description: "Winners for this raffle have already been picked",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    let pickWinners = (
      await client.mutate({
        mutation: PICK_WINNERS,
        variables: {
          walletAddress: address,
          itemId: id,
          signature,
        },
      })
    ).data.pickRaffleWinners;

    if (pickWinners !== "") {
      toast({
        title: "Error",
        description: pickWinners,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Success",
        description: "Picked Winners, Check your Downloads",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      rounded={"lg"}
      display={"flex"}
      flexDirection={"column"}
      bg={"#152243"}
      borderRadius={"10px"}
    >
      <Container
        display={"flex"}
        borderTopRadius={"10px"}
        textAlign={"left"}
        bgImage={image}
        backgroundPosition={"center"}
        bgSize={"cover"}
        bgBlendMode={"darken"}
        bgColor={"rgba(0,0,0,.75)"}
        height="55%"
      >
        <Image
          position={"relative"}
          my={10}
          width={"40%"}
          objectFit={"cover"}
          borderRadius={"20px"}
          src={image}
        />
        <Stat width={"60%"} pl={5} my={10}>
          <StatLabel
            fontFamily={"Lato"}
            fontWeight={"800"}
            fontSize={"17px"}
            mb={0}
          >
            {name}
          </StatLabel>
          <StatHelpText fontSize={"10px"} m={0}>
            Price
          </StatHelpText>
          <StatNumber fontSize={"17px"} mb={0}>
            {price} $AQUA
          </StatNumber>
          <Container
            display={"flex"}
            flexDir="row"
            m="0"
            p="0"
            justifyContent={"flex-start"}
          >
            <Container m={0} p={0} mr="5" width="none">
              <StatHelpText fontSize={"10px"} m={0}>
                {type === "raffle" ? "# of Entries" : "Supply Remaining"}
              </StatHelpText>
              <StatNumber fontSize={"17px"} textAlign={"left"}>
                {type === "raffle"
                  ? `${supply.remaining}`
                  : `${supply.remaining}/${supply.total}`}
              </StatNumber>
            </Container>
            {type === "raffle" && (
              <Container m={0} p={0} width="none">
                <StatHelpText fontSize={"10px"} m={0}>
                  # of Winners
                </StatHelpText>
                <StatNumber fontSize={"17px"} textAlign="left">
                  {supply.total}
                </StatNumber>
              </Container>
            )}
          </Container>
          <StatHelpText fontSize={"10px"} m={0}>
                Expiration Date
          </StatHelpText>
              <StatNumber fontSize={"14px"} textAlign={"left"}>
                {expirationDate?dateString(expirationDate):"None"}
              </StatNumber>
        </Stat>
      </Container>
      <Box
        height={"2px"}
        background={"linear-gradient(90deg, #4D80E2 4.94%, #9F79E1 100%)"}
      />
      <Container
        p={4}
        pb={8}
        height="45%"
        display={"flex"}
        flexDirection={"column"}
        bgSize={"fill"}
        bgPosition={"bottom"}
      >
        <Container
          borderRadius={"10px"}
          background={"#1F2B4C"}
          fontFamily="Lato"
          fontSize={"16px"}
          color={"rgba(255,255,255,.62)"}
          textAlign={"left"}
          width={"100%"}
          mb={6}
          py={1}
          overflow="auto"
          minH={"50%"}
        >
          {description}
        </Container>
        <Container
          display={"flex"}
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
          px={0}
        >
          <Button
            size={"lg"}
            width={"40%"}
            py="5"
            mt="auto"
            alignSelf={"left"}
            fontFamily={"Lato"}
            bg={(expirationDate && (new Date()) > (new Date(expirationDate)))?
              "EXPIRED":
              ((type === "raffle")? 
                ((winners.length>0)? "#1f2b4c" : "#4D80E2"):
                ((supply.remaining <= 0) || 
                (winners.some((winner) => winner.walletAddress === walletAddress))? "#1f2b4c" : "#4D80E2"))}
            color={(expirationDate && (new Date()) > (new Date(expirationDate)))?
              "EXPIRED":
              ((type === "raffle")?
                ((winners.length>0)? "#545d76" : "white"):
                ((supply.remaining <= 0) || 
                (winners.some((winner) => winner.walletAddress === walletAddress))? "#545d76" : "white"))}
            onClick={() => {
              purchase(id);
            }}
            disabled={(expirationDate && (new Date()) > (new Date(expirationDate)))?
              "EXPIRED":
              ((type === "raffle")? 
                ((winners.length>0)? true : false):
                ((supply.remaining <= 0) || 
                (winners.some((winner) => winner.walletAddress === walletAddress))? true : false))}
          >
            {(expirationDate && (new Date()) > (new Date(expirationDate)))?
              "EXPIRED":
              ((type === "raffle")?
                ((winners.length>0)?"EXPIRED":"BUY"):
                  ((supply.remaining <= 0)?"SOLD OUT":
                  ((winners.some((winner) => winner.walletAddress === walletAddress))? "PURCHASED" : "BUY")))}
          </Button>
          {type === "raffle" && display && (
            <>
              <CSVLink
                ref={raffleCSVLink}
                filename={`${name}-winners`}
                data={winners.map((item) => {
                  return {
                    discordID: item.discordID,
                    wallet: item.walletAddress,
                    whitelistAddress: item.whitelistAddress,
                  };
                })}
                headers={[
                  { label: "Discord ID", key: "discordID" },
                  { label: "Wallet Address", key: "wallet" },
                  { label: "WL Address", key: "whitelistAddress" },
                ]}
              />
              <IconButton
                ml="auto"
                mr="2"
                onClick={async () => {
                  await pickRaffleWinners();
                  await refetchWinners({ id: walletAddress });
                  raffleCSVLink.current.link.click();
                }}
                icon={
                  <Icon as={ImTicket} w={10} h={10} p={2} borderRadius="10px" />
                }
              />
            </>
          )}
          {display && (
            <>
              <CSVLink
                ref={csvLink}
                filename={`${name}-winners`}
                data={winners.map((item) => {
                  return {
                    discordID: item.discordID,
                    wallet: item.walletAddress,
                    whitelistAddress: item.whitelistAddress,
                  };
                })}
                headers={[
                  { label: "Discord ID", key: "discordID" },
                  { label: "Wallet Address", key: "wallet" },
                  { label: "WL Address", key: "whitelistAddress" },
                ]}
              />
              <IconButton
                onClick={async () => {
                  await refetchWinners({ id: walletAddress });
                  csvLink.current.link.click();
                }}
                icon={<DownloadIcon w={10} h={10} p={2} borderRadius="10px" />}
              />
            </>
          )}
        </Container>
      </Container>
    </Box>
  );
}

export default function ShopItems({ client, walletAddress }) {
  const useQueryMultiple = () => {
    const res1 = useQuery(GET_ALL_ITEMS, { pollInterval: 3000 });
    const res2 = useQuery(GET_ROLE, { variables: { id: walletAddress } });
    const res3 = useQuery(GET_WINNERS, { variables: { id: walletAddress } });
    return [res1, res2, res3];
  };

  const [
    { loading: loading1, error: error1, data: data1, refetch: refetch1 },
    { loading: loading2, error: error2, data: data2, refetch: refetch2 },
    { loading: loading3, error: error3, data: data3, refetch: refetch3 },
  ] = useQueryMultiple();

  useEffect(() => {
    const refetchItemsWinners = async ()=> {
      await refetch1({ id: walletAddress }); 
      await refetch3({ id: walletAddress });
    }

    document.addEventListener("itemRefresh", refetchItemsWinners);
    document.addEventListener("discordUpdate", refetch2);

    return () => {
      document.removeEventListener("itemRefresh", refetchItemsWinners);
      document.removeEventListener("discordUpdate", refetch2);
    }
  }, [walletAddress, refetch1, refetch2, refetch3]);

  useEffect(() => {
    refetch2({ id: walletAddress });
    refetch3({ id: walletAddress });
  }, [walletAddress, refetch2, refetch3]);

  return walletAddress.length > 0 && (
    <Box
      maxW="7xl"
      mx={"auto"}
      mb={10}
      pt={5}
      pb={5}
      px={{ base: 2, sm: 12, md: 17 }}
    >
      {error1 && (
        <Heading fontFamily={"Lato"} size={"4xl"}>
          {error1.toString()}
        </Heading>
      )}
      {loading1 && (
        <Heading fontFamily={"Lato"} size={"4xl"}>
          Loading.....
        </Heading>
      )}
      <SimpleGrid columns={[1, 1, 2, 2, 3, 3]} spacing="40px">
        {!error1 &&
          !loading1 &&
          data1.allItems.map(
            (item, index) =>
              item.saleStatus && (
                <ShopItem
                  id={item._id}
                  key={index}
                  name={item.name}
                  image={item.image}
                  price={item.price}
                  type={item.type}
                  expirationDate={item.expirationDate}
                  supply={{ remaining: item.stock, total: item.supply }}
                  description={item.description}
                  client={client}
                  display={
                    !loading2 && !error2 && data2.user
                      ? data2.user.role === "team"
                      : false
                  }
                  discordID={data2?.user?.discordID}
                  winners={
                    !loading3 && !error3 && data3.allWinners.length > 0
                      ? data3.allWinners[index].winners
                      : []
                  }
                  refetchWinners={refetch3}
                  walletAddress={walletAddress}
                />
              )
          )}
      </SimpleGrid>
    </Box>
  );
}
