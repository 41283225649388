import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createMemoryHistory } from "history";
import Main from "./pages/Main";
import Mint from "./pages/Mint";
import Shop from "./pages/Shop";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import PrivacyPolicy from "./pages/PrivacyPolicy";

// Initialize Apollo Client
const client = new ApolloClient({
  uri: (process.env.NODE_ENV === "development")
  ? 'http://localhost:5000/'
  : 'https://aquatools-shop-api.herokuapp.com/',
  cache: new InMemoryCache()
});

export default function App() {
  const history = createMemoryHistory();
  history.push("/");

  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <Routes>
          <Route path="/" element={<Main></Main>}></Route>
          <Route path="/mint" element={<Mint></Mint>}></Route>
          <Route path="/shop" element={<Shop client={client}></Shop>}></Route>
          <Route path="/privacy" element={<PrivacyPolicy></PrivacyPolicy>}></Route>
        </Routes>
      </Router>
    </ApolloProvider>
  );
}
