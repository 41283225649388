import React from "react";
import { Box } from "@chakra-ui/react";

import Navbar from "../components/NavBar";

import Mint from "../components/Mint";
import Stats from "../components/Stats";

function MintPage() {
  return (
    <Box
      textAlign="center"
      fontSize="xl"
      style={{
        backgroundColor: "#11182b",
      }}
    >
      <Navbar />
      <Mint />
      <Stats />
    </Box>
  );
}

export default MintPage;
