import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Square,
  Icon,
  Container,
} from "@chakra-ui/react";
import { MdTrendingUp, MdAccountBalance, MdHttps } from "react-icons/md";
import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

const emissionRate = 10;

function StatsCard(props) {
  const { title, stat, background, icon } = props;

  return (
    <Container
      width={"100%"}
      px={"8"}
      py={"8"}
      backgroundColor={background}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
      display={"flex"}
      flexDirection={"row"}
    >
      <Stat>
        <StatLabel
          fontSize={"22px"}
          fontFamily={"'Lato', sans-serif"}
          fontWeight={"600"}
          textAlign={"left"}
          color={"rgba(255, 255, 255, 0.42)"}
          isTruncated
        >
          {title}
        </StatLabel>
        <StatNumber
          fontFamily={"'Lato', sans-serif"}
          fontStyle={"black"}
          fontSize={"26px"}
          fontWeight={"900"}
          textAlign={"left"}
        >
          {stat}
        </StatNumber>
      </Stat>
      <Square
        size={"70px"}
        borderRadius={"30px"}
        background={"rgba(255,255,255,.14)"}
        alignSelf={"center"}
      >
        <Icon as={icon}></Icon>
      </Square>
    </Container>
  );
}

// GQL Queries
const GET_USER = gql`
  query GetUser($id: ID!) {
    user(ID: $id) {
      passesOwned
      tokensOwned
      discordID
      whitelistAddress
    }
  }
`;

export default function Stats({ walletAddress, passesStaked, passesOwned }) {
  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { id: "" },
  });

  useEffect(() => {
    refetch({ id: walletAddress });
    document.addEventListener("statsRefresh", handleStatsRefresh);

    return () =>
      document.removeEventListener("statsRefresh", handleStatsRefresh);
  });

  const handleStatsRefresh = async () => {
    refetch({ id: walletAddress });
  };

  return (
    walletAddress.length > 0 && (
      <Box
        maxW="7xl"
        mx={"auto"}
        mt={10}
        pt={5}
        px={{ base: 2, sm: 12, md: 17 }}
      >
        <SimpleGrid
          minChildWidth={"350px"}
          columns={{ base: 1, md: 3 }}
          spacing={{ base: 5, lg: 8 }}
        >
          <StatsCard
            title={"Balance"}
            stat={
              error
                ? `${error}`
                : loading || data?.user?.tokensOwned === undefined
                ? "Loading..."
                : `${data.user.tokensOwned} $AQUA`
            }
            background={"#9c69e9"}
            icon={MdAccountBalance}
          />
          <StatsCard
            title={"Passes Staked"}
            stat={passesStaked === 1 ? `1 Pass` : `${passesStaked} Passes`}
            background={"#4d81e2"}
            icon={MdHttps}
            passesOwned={passesOwned}
            passesStaked={passesStaked}
          />
          <StatsCard
            title={"Gain per Day"}
            stat={`${passesStaked * emissionRate} $AQUA`}
            background={"#596a8b"}
            icon={MdTrendingUp}
          />
        </SimpleGrid>
      </Box>
    )
  );
}
