import React from "react";

import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  HStack,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  CloseButton,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineMenu } from "react-icons/ai";
import { ethers } from "ethers";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const bg = useColorModeValue("white", "gray.800");
  const mobileNav = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  let message;
  let address;
  let signature;

  const connectOnClick = async () => {
    if (!window.ethereum) {
      toast({
        title: "Error",
        description: "Metamask not found.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      message = "aquatools";
      signature = await signer.signMessage(message);
      address = await signer.getAddress();
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.post(
        process.env.NODE_ENV === "development"
          ? "http://localhost:4000/auth/signin"
          : "https://api.aquatools-nft.com/auth/signin",
        {
          message,
          address,
          signature,
        },
        { withCredentials: true }
      );

      toast({
        title: "Success",
        description: "Success! Please return to OpenSea and refresh your page.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 400) {
        toast({
          title: "Error",
          description:
            "Unauthorized, make sure your wallet address is the one holding the AquaPass.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Server is unavaliable. Please try again soon.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <React.Fragment>
      <chakra.header
        bg={bg}
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        shadow="md"
        style={{
          background:
            "linear-gradient(90deg, rgba(67, 117, 222, 1) 0%, rgba(105, 113, 222, 1) 49%, rgba(149, 110, 222, 1) 100%)",
        }}
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <img
              src="AquaTools_white.png"
              alt="AquaTools"
              style={{
                maxHeight: "35px",
                maxWidth: "35px",
                marginLeft: "12px",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={1}
              mr={1}
              color="brand.500"
              display={{ base: "none", md: "inline-flex" }}
            >
              <Button
                variant="ghost"
                onClick={() => {
                  window.open(
                    `https://twitter.com/AquaToolsNFT`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                Twitter
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  window.open(
                    `https://discord.gg/YptEXdr7Th`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                Discord
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  window.open(
                    `https://opensea.io/collection/aquatools/`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                OpenSea
              </Button>
              <Button variant="ghost" onClick={connectOnClick}>
                Connect
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  navigate("/shop");
                }}
              >
                Marketplace
              </Button>
            </HStack>

            <Box display={{ base: "inline-flex", md: "none" }}>
              <IconButton
                display={{ base: "flex", md: "none" }}
                aria-label="Open menu"
                fontSize="20px"
                color={useColorModeValue("gray.800", "inherit")}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={mobileNav.onOpen}
              />

              <VStack
                pos="absolute"
                top={0}
                left={0}
                right={0}
                display={mobileNav.isOpen ? "flex" : "none"}
                flexDirection="column"
                p={2}
                pb={4}
                m={2}
                bg={bg}
                spacing={3}
                rounded="sm"
                shadow="sm"
              >
                <CloseButton
                  aria-label="Close menu"
                  onClick={mobileNav.onClose}
                />

                <Button
                  w="full"
                  variant="ghost"
                  onClick={() => {
                    window.open(
                      `https://twitter.com/AquaToolsNFT`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  Twitter
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    window.open(
                      `https://discord.gg/YptEXdr7Th`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  Discord
                </Button>
                <Button
                  w="full"
                  variant="ghost"
                  onClick={() => {
                    window.open(
                      `https://opensea.io/collection/aquatools/`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  OpenSea
                </Button>
                <Button w="full" variant="ghost" onClick={connectOnClick}>
                  Connect
                </Button>
                <Button
                  w="full"
                  variant="ghost"
                  onClick={() => {
                    navigate("/shop");
                  }}
                >
                  Marketplace
                </Button>
              </VStack>
            </Box>
          </HStack>
        </Flex>
      </chakra.header>
    </React.Fragment>
  );
}
