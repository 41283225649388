import React, { useState } from "react";
import FocusLock from "react-focus-lock";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  ButtonGroup,
  useDisclosure,
} from "@chakra-ui/react";
import { MdBuild } from "react-icons/md";

import { ethers } from "ethers";
import { gql, useQuery } from "@apollo/client";

const GET_USER = gql`
  query user($ID: ID!) {
    user(ID: $ID) {
      _id
      discordID
      whitelistAddress
      passesOwned
      tokensOwned
      role
    }
  }
`;

const EDIT_USER = gql`
  mutation Mutation(
    $walletAddress: ID!
    $wlAddress: String
    $discordID: String
    $signature: String
  ) {
    editUser(
      walletAddress: $walletAddress
      wlAddress: $wlAddress
      discordID: $discordID
      signature: $signature
    )
  }
`;

const TextInput = React.forwardRef(({ setAccountSettings, ...props }, ref) => {
  const onFieldChange = (e) => {
    if (props.id === "wl-address") {
      setAccountSettings({
        wlAddress: e.target.value,
        discordID: props.data?.user?.discordID,
      });
    } else {
      setAccountSettings({
        wlAddress: props.data?.user?.whitelistAddress,
        discordID: e.target.value,
      });
    }
  };

  return (
    <FormControl>
      <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
      <Input
        ref={ref}
        id={props.id}
        {...props}
        onChange={(e) => onFieldChange(e)}
      />
    </FormControl>
  );
});

const Form = ({
  firstFieldRef,
  onCancel,
  onSave,
  accountSettings,
  setAccountSettings,
  data,
}) => {
  return (
    <Stack spacing={4}>
      <TextInput
        label="Discord ID"
        id="discord-id"
        ref={firstFieldRef}
        defaultValue={data?.user?.discordID}
        setAccountSettings={setAccountSettings}
      />
      <TextInput
        label="WL Address"
        id="wl-address"
        defaultValue={data?.user?.whitelistAddress}
        setAccountSettings={setAccountSettings}
        data={data}
      />
      <ButtonGroup display="flex" justifyContent="flex-end">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button colorScheme="purple" onClick={onSave}>
          Save
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

const PopoverForm = ({ walletAddress, client }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);
  const [accountSettings, setAccountSettings] = useState({
    discordID: "",
    wlAddress: "",
  });

  const { loading, error, data } = useQuery(GET_USER, {
    variables: { ID: walletAddress },
  });

  const saveAccountSettings = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage("Edit account");

    let editErrMessage = await client.mutate({
      mutation: EDIT_USER,
      variables: {
        walletAddress: walletAddress,
        wlAddress: accountSettings.wlAddress,
        discordID: accountSettings.discordID,
        signature: signature,
      },
    });
    const event = new Event("discordUpdate");
    document.dispatchEvent(event);
  };

  return (
    <>
      {!error && !loading && data?.user && (
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          placement="right"
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <Button leftIcon={<MdBuild />} mr="3" colorScheme="gray">
              Manage Account
            </Button>
          </PopoverTrigger>
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <Form
                firstFieldRef={firstFieldRef}
                accountSettings={accountSettings}
                setAccountSettings={setAccountSettings}
                data={data}
                onCancel={onClose}
                onSave={async () => {
                  await saveAccountSettings();
                  onClose();
                }}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};

export default PopoverForm;
