import React from "react";
import {
  chakra,
  Box,
  useColorModeValue,
  Button,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

const Hero = () => {
  const navigate = useNavigate();
  const mintLive = false;

  return (
    <Box px={8} py={14} mx="auto">
      <Box
        w={{ base: "full", md: 11 / 12, xl: 9 / 12 }}
        mx="auto"
        textAlign={{ base: "left", md: "center" }}
      >
        <chakra.h1
          mb={6}
          fontSize={{ base: "4xl", md: "6xl" }}
          fontWeight="bold"
          lineHeight="none"
          letterSpacing={{ base: "normal", md: "tight" }}
          color={useColorModeValue("gray.900", "gray.100")}
        >
          <Text
            display={{ base: "block", lg: "inline" }}
            w="full"
            bgClip="text"
            bgGradient="linear(to-r, blue.400,purple.500)"
            fontWeight="extrabold"
          >
            AquaTools
          </Text>
        </chakra.h1>
        <chakra.p
          px={{ base: 0, lg: 24 }}
          mb={6}
          fontSize={{ base: "lg", md: "xl" }}
          color={useColorModeValue("gray.600", "gray.300")}
        >
          AquaTools NFTs represent a token that operates as an access pass. It
          provides access to a premier toolbox for analyzing and acquiring the
          most coveted NFT collections.
        </chakra.p>
        <Stack
          direction={{ base: "column", sm: "row" }}
          mb={{ base: 4, md: 8 }}
          spacing={2}
          justifyContent={{ sm: "left", md: "center" }}
        >
          <Button
            as="a"
            variant="solid"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            w={{ base: "full", sm: "auto" }}
            mb={{ base: 2, sm: 0 }}
            size="lg"
            cursor="pointer"
            style={{
              background:
                "linear-gradient(271.13deg, #b889ff -18.58%, #4a80e2 120.87%)",
            }}
            onClick={() => {
              if (mintLive) {
                navigate("/mint");
              }
            }}
          >
            {mintLive ? "Mint" : "Sold Out"}
          </Button>
        </Stack>
      </Box>
      <Box
        w={{ base: "full", md: 10 / 12 }}
        mx="auto"
        mt={20}
        textAlign="center"
      >
        <div
          className="player-wrapper"
          style={{ position: "relative", paddingTop: "55%" }}
        >
          <ReactPlayer
            url={
              "https://player.vimeo.com/video/683127511?h=d5fe51968a&muted=1&autoplay=1&controls=0&loop=1&background=1&app_id=122963"
            }
            playing={true}
            muted
            loop
            playsinline
            width="100%"
            height="100%"
            controls={false}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
            }}
          />
        </div>
      </Box>
    </Box>
  );
};

export default Hero;
