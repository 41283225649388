import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";

const Testimonial = ({ children }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }) => {
  return (
    <Heading as={"h3"} fontSize={"xl"}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }) => {
  return (
    <Text
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({ src, name, title }) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align={"center"}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={"sm"} color={useColorModeValue("gray.600", "gray.400")}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export default function Profiles() {
  return (
    <Box>
      <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} textAlign={{ base: "left", md: "center" }}>
          <Heading>The Team</Heading>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Marketing/Operations</TestimonialHeading>
              <TestimonialText>
                Marketing at HAPE. Prada has presided as a consultant,
                moderator, and manager of many initiatives in the Web3 space.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                "https://cdn.discordapp.com/attachments/917903443035840515/935291072479920208/Prada_Profile.png"
              }
              name={"NFTPrada"}
              title={"Marketing/Operations"}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Developer</TestimonialHeading>
              <TestimonialText>
                Currently a software engineer at a Fortune 500 company and a
                student of MIT. Chroma is now bringing her talents to the Web3
                space.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                "https://cdn.discordapp.com/attachments/917903443035840515/935291396825436180/Chroma_Profile.png"
              }
              name={"Chroma"}
              title={"Developer"}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Developer</TestimonialHeading>
              <TestimonialText>
                Currently a software engineer at Salesforce. Jack is now
                bringing their innovative visions to life in the Web3 space.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                "https://cdn.discordapp.com/attachments/917903443035840515/935291071657836624/Jacquemus_Profile.png"
              }
              name={"Jack"}
              title={"Developer"}
            />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
}
