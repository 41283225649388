import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
} from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";
import { React } from "react";
import { gql, useQuery } from "@apollo/client";

// GQL Queries
const GET_TRANSACTIONS = gql`
  query Transactions($address: String!) {
    transactions(address: $address) {
      address
      transactionType
      itemName
      itemType
      price
      createdAt
    }
  }
`;

export default function PurchaseHistory({ walletAddress }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading, error, data, refetch } = useQuery(GET_TRANSACTIONS, {
    variables: { address: walletAddress },
  });

  return (
    <>
      {walletAddress && (
        <Button
          leftIcon={<TimeIcon />}
          fontFamily={"Lato"}
          fontSize={"16px"}
          size={"md"}
          onClick={async () => {
            await refetch({ address: walletAddress });
            onOpen();
          }}
        >
          View Transaction History
        </Button>
      )}

      <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transaction History</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer
              bg={"rgba(255, 255, 255, 0.05)"}
              borderRadius="10px"
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Type</Th>
                    <Th>Item Name</Th>
                    <Th>Price ($AQUA)</Th>
                    <Th>Item Type</Th>
                    <Th>Purchase Time</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {!loading &&
                    !error &&
                    [...data.transactions]
                      .sort((a, b) => b.createdAt - a.createdAt)
                      .map((transaction, index) => {
                        let purchaseTime = new Date(
                          parseInt(transaction.createdAt)
                        );
                        return (
                          <Tr key={index}>
                            <Td>{transaction.transactionType}</Td>
                            <Td>{transaction.itemName}</Td>
                            <Td>{transaction.price}</Td>
                            <Td>{transaction.itemType}</Td>
                            <Td>{purchaseTime.toLocaleString("en-US")}</Td>
                          </Tr>
                        );
                      })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
