import { ethers } from 'ethers';

export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          description: "Connected to Wallet " + ethers.utils.getAddress(addressArray[0]),
          type: "success",
          address: ethers.utils.getAddress(addressArray[0]),
        };
        return obj;
      } catch (err) {
        return {
            address: "",
            type: "error",
            description: err.message,
        };
      }
    } else {
        return {
            address: "",
            type: "error",
            description: "You must install Metamask, a virtual Ethereum wallet, in your browser."
        };
    }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        let storedAddress= localStorage.getItem('walletAddress');
        if(storedAddress && storedAddress !== "" && addressArray[0].length > 0){
          return {
            address: ethers.utils.getAddress(addressArray[0]),
            type: "success",
            description: "Connected to Wallet " + ethers.utils.getAddress(addressArray[0]),
        };
        } else {
          return {
            address: "",
            type: "error",
            description: "Connect to Metamask 🦊 using the Connect Wallet button",
          };
        }
      } catch (err) {
        return {
          address: "",
          type: "error",
          description: err.message,
        };
      }
    } else {
      return {
        address: "",
        type: "error",
        description: "You must install Metamask, a virtual Ethereum wallet, in your browser."
        }
    }
};