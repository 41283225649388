import React from "react";

import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

const features = [
  {
    id: 0,
    title: "Quickbuy",
    text: "Automation of purchasing NFTs on the secondary market. The enables a faster checkout without unnecessary delays to secure your desired NFT as other manual users fail their transactions.",
  },
  {
    id: 1,
    title: "Floor Display",
    text: "Floor prices of respective collections will be displayed on OpenSea as you peruse through collections to collect or when you’re sniping NFTs. Listings are monitored and updated in real time.",
  },
  {
    id: 2,
    title: "Rarity Ranker",
    text: "Rarity tracking and subsequent ranking of traits of NFT collections will be displayed in real time on OpenSea as you peruse through collections to collect or when you’re sniping NFTs.",
  },
  {
    id: 3,
    title: "Listing Monitor",
    text: "Monitor new NFT listings based on trait, price, and rarity. Toasts will populate on screen to distinguish listings the second they get uploaded to OpenSea.",
  },
  {
    id: 4,
    title: "Contract Minter",
    text: "Mint NFTs directly off the contract for public launches. The AquaHQ minting module will be able to detect when contracts go live and send your transaction immediately.",
  },
  {
    id: 5,
    title: "Bid Bot",
    text: "Automating the bid process and allowing the option to bid based on specific ID's, prices, and rarity.",
  },
];

export default function Features() {
  return (
    <Box p={4} marginBottom={"40px"}>
      <Stack
        spacing={4}
        as={Container}
        maxW={"3xl"}
        textAlign={{ base: "left", md: "center" }}
      >
        <Heading fontSize={"3xl"}>Features</Heading>
        <Text color={"gray.600"} fontSize={"xl"}>
          We are constantly providing updates and improvements to our product.
        </Text>
      </Stack>

      <Container maxW={"6xl"} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={"top"} textAlign={"left"}>
              <Box color={"green.400"} px={2}>
                <Icon as={CheckIcon} color={"#a387f9"} />
              </Box>
              <VStack align={"start"}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={"gray.600"}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
