import {
  Box,
  Image,
  Container,
  Stat,
  StatLabel,
  StatHelpText,
  StatNumber,
  Heading,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import { React, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

// GQL Queries
const GET_TRANSACTIONS = gql`
query Transactions($address: String!) {
  transactions(address: $address) {
    address
    transactionType
    image
    itemName
    itemType
    price
    createdAt
  }
}
`;

function PurchasedItem(props) {
  const {
    name,
    image,
    date,
    price,
  } = props;

  return (
    <Box
      rounded={"lg"}
      display={"flex"}
      flexDirection={"column"}
      bg={"#152243"}
      borderRadius={"20px"}
      height="100%"
    >
      <Container
        display={"flex"}
        borderRadius={"10px"}
        textAlign={"left"}
        bgImage={image}
        backgroundPosition={"center"}
        bgSize={"cover"}
        bgBlendMode={"darken"}
        bgColor={"rgba(0,0,0,.75)"}
        height="100%"
      >
        <Image
          position={"relative"}
          my={10}
          width={"40%"}
          objectFit={"cover"}
          borderRadius={"20px"}
          src={image}
        />
        <Stat width={"60%"} pl={5} my={10}>
            <StatLabel
            fontFamily={"Lato"}
            fontWeight={"800"}
            fontSize={"17px"}
            mb={3}
            >
            {name}
            </StatLabel>
            <StatHelpText fontSize={"10px"} m={0}>
            Price
            </StatHelpText>
            <StatNumber fontSize={"17px"} mb={3}>
            {price} $AQUA
            </StatNumber>
            <StatHelpText fontSize={"10px"} m={0}>
                Time Purchased
            </StatHelpText>
            <StatNumber fontSize={"14px"} textAlign={"left"}>
                {date}
            </StatNumber>
        </Stat>
    </Container>
    </Box>
    )
}

export default function PurchasedItems({ client, walletAddress }) {
  const { loading, error, data, refetch } = useQuery(GET_TRANSACTIONS, {
    variables: { address: walletAddress },
  });

  useEffect(() => {
    document.addEventListener("transactionsRefresh", refetch);

    return () => document.removeEventListener("transactionsRefresh", refetch);
  });

  useEffect(() => {
    refetch({ id: walletAddress });
  }, [walletAddress, refetch]);

  return (
    <Box
      maxW="7xl"
      mx={"auto"}
      mb={10}
      pt={5}
      pb={5}
      px={{ base: 2, sm: 12, md: 17 }}
    >
    
    {error && (
        <Heading fontFamily={"Lato"} size={"4xl"}>
          {error.toString()}
        </Heading>
      )}
      {loading && (
        <Heading fontFamily={"Lato"} size={"4xl"}>
          Loading.....
        </Heading>
      )}
      <SimpleGrid columns={[1, 1, 2, 2, 3, 3]} spacing="40px" >
        {!loading && !error &&
        [...data.transactions]
        .sort((a, b) => b.createdAt - a.createdAt).map((transaction, index) => {
          let purchaseTime = new Date(
            parseInt(transaction.createdAt)
          );
          if (transaction.transactionType === "Purchase" && 
              transaction.itemType === "WL") {
            return (
              <GridItem key={index} >
                <PurchasedItem
                  name={transaction.itemName}
                  image={transaction.image}
                  date={purchaseTime.toLocaleString("en-US")}
                  price={transaction.price}
                /> 
              </GridItem>
            );
          } else return (null);
        })}
      </SimpleGrid>
    </Box>
  );
}
  