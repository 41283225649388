import { Button, Icon, useToast } from "@chakra-ui/react";
import { React, useEffect } from "react";
import { connectWallet, getCurrentWalletConnected } from "../utils/interact";
import { ethers } from "ethers";

const CircleIcon = (props) => (
  <Icon mr="8px" p="1px" viewBox="0 0 200 200" {...props}>
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
);

export default function WalletButton({ walletAddress, setWallet }) {
  const toast = useToast();

  const walletResponseToast = ({ description, type }) => {
    toast({
      description: description,
      status: type,
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    const addWalletListener = () => {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", (accounts) => {
          if (accounts.length > 0) {
            setWallet(ethers.utils.getAddress(accounts[0]));
            walletResponseToast({
              type: "success",
              description: "Connected to Wallet " + ethers.utils.getAddress(accounts[0])
            })
          } else {
            setWallet("");
          }
        });
      } else {
        walletResponseToast({
          address: "",
          type: "error",
          description:
            "You must install Metamask, a virtual Ethereum wallet, in your browser.",
        });
      }
    };

    const setUpWalletListener = async () => {
      const walletResponse = await getCurrentWalletConnected();
      setWallet(walletResponse.address);
      walletResponseToast(walletResponse);
      addWalletListener();
    };
    setUpWalletListener();
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    if (walletAddress.length > 0) {
      setWallet("");
      walletResponseToast({
        description: "Disconnected from Wallet",
        type: "success",
      });
    } else {
      setWallet(walletResponse.address);
      // walletResponseToast(walletResponse);
    }
  };

  return (
    <Button borderRadius={"50px"} onClick={connectWalletPressed}>
      <CircleIcon color={walletAddress.length > 0 ? "green.500" : "red.500"} />
      {walletAddress.length > 0
        ? `${walletAddress.substring(0, 6)}...${walletAddress.substring(38)}`
        : "Connect Wallet"}
    </Button>
  );
}
