import React from "react";
import { Flex, Box, Spacer, Button, ButtonGroup, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import WalletButton from "./WalletButton";
import PopoverForm from "./ManageAccount";
import PurchaseHistory from "./PurchaseHistory";

const AccountBar = ({ setWallet, walletAddress, client, passesOwned }) => {
  const navigate = useNavigate();

  return (
    <Flex minWidth="max-content" alignItems="center" gap="2" p="3" bg="#070E1F">
      <Box p="2">
        <Button mr="3" bgColor="#B889FF" onClick={() => navigate("/")}>
          Home
        </Button>
        <PopoverForm walletAddress={walletAddress} client={client} />
        <PurchaseHistory walletAddress={walletAddress} />
      </Box>
      <Spacer />
      <ButtonGroup gap="2" display={"flex"} justifyContent="center" alignItems={"center"}>
        <Text fontFamily={"Lato"} fontWeight="bold">
          {`Passes Owned: ${passesOwned}`} 
        </Text>
        <WalletButton walletAddress={walletAddress} setWallet={setWallet} />
      </ButtonGroup>
    </Flex>
  );
};

export default AccountBar;
