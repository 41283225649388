import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
  Button,
  Container,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import { ethers } from "ethers";
import AquaTools from "../artifacts/contracts/AquaTools.sol/AquaTools.json";
import AquaStaking from "../artifacts/contracts/AquaStaking.sol/AquaStaking.json";

const stakingContractAddress = "0xD96A3c1FAF50832E09a465C5c202FC19d5C8aC45";
const aquaToolsContractAddress = "0x76f3a1ef80a1142f9bd2b868d59ab75a1c0916d4";

var provider, signer, aquaStakingContract, aquaToolsContract;
if(window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();

  aquaStakingContract = new ethers.Contract(
    stakingContractAddress,
    AquaStaking.abi,
    signer
  );
  aquaToolsContract = new ethers.Contract(
    aquaToolsContractAddress,
    AquaTools.abi,
    signer
  );
}


function StakingCard(props) {
  const { title, background, walletAddress, passesStaked, setPassesStaked, passesOwned } = props;
  const [numStakes, setnumStakes] = useState(0);
  const toast = useToast();
  const toastIdRef = useRef();

  const getPassesStaked = async () => {
    const amt = await aquaStakingContract.amountStaked(walletAddress);
    setPassesStaked(parseInt(amt));
  };

  const handleStake = async () => {
    if (!window.ethereum) {
      toast({
        title: "Error",
        description: "Metamask not found.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    if (numStakes === 0) {
      toast({
        title: "Error",
        description: "You cannot Stake 0 passes",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    if (window.ethereum.chainId !== "0x1") {
      toast({
        title: "Error",
        description: "Please connect to mainnet.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    const signerAddress = await signer.getAddress();

    const isApproved = await aquaToolsContract.isApprovedForAll(
      signerAddress,
      stakingContractAddress
    );

    if (!isApproved) {
      let tx = await aquaToolsContract.setApprovalForAll(stakingContractAddress, true);
      await tx.wait();
    }

    try {
      let tx = await aquaStakingContract.stake(numStakes);
      setnumStakes(0);
      toastIdRef.current = toast({
        title: "Success",
        description: "Staking, Please Wait...",
        status: "success",
        duration: 20000,
        isClosable: true,
      });
      await tx.wait();
      toast.close(toastIdRef.current);
      toast({
        title: "Success",
        description: "Passes staked",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      getPassesStaked();
      const event = new Event("stakingRefresh");
      document.dispatchEvent(event);
    } catch (e) {
      const errMsg = e.data
        ? e.data.message.split("execution reverted: ")[1]
        : e.message;

      toast({
        title: "Error",
        description: "Staking failed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      toast.close(toastIdRef.current);
    }
  };

  const handleUnstake = async () => {
    if (!window.ethereum) {
      toast({
        title: "Error",
        description: "Metamask not found.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    if (numStakes === 0) {
      toast({
        title: "Error",
        description: "You cannot Unstake 0 passes",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    if (window.ethereum.chainId !== "0x1") {
      toast({
        title: "Error",
        description: "Please connect to mainnet.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    try {
      let tx = await aquaStakingContract.unstake(numStakes);
      setnumStakes(0);
      toastIdRef.current = toast({
        title: "Success",
        description: "Unstaking, Please Wait...",
        status: "success",
        duration: 20000,
        isClosable: true,
      });
      await tx.wait();
      toast({
        title: "Success",
        description: "Passes unstaked",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      toast.close(toastIdRef.current);
      getPassesStaked();
      const event = new Event("stakingRefresh");
      document.dispatchEvent(event);
    } catch (e) {
      const errMsg = e.data
        ? e.data.message.split("execution reverted: ")[1]
        : e.message;
      toast({
        title: "Error",
        description: "Unstaking Failed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      toast.close(toastIdRef.current);
    }
  };

  return (
    <Container
      maxW={"100%"}
      px={"6"}
      py={"8"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}  
      backgroundColor={background}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
    >
        <Text
          mr="auto"
          fontSize={"25px"}
          fontFamily={"'Lato', sans-serif"}
          fontWeight={"600"}
          textAlign={"left"}
          color={"white"}
          alignSelf={"center"}
          isTruncated
        >
          {title} Passes
        </Text>
        <NumberInput
          size="lg"
          maxW={20}
          onChange={(val) => setnumStakes(val)}
          value={numStakes}
          min={0}
          max={title === "Stake" ? passesOwned : passesStaked}
          ml={"auto"}
          mr={5}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Button
          width="134px"
          fontFamily={"Lato"}
          fontSize={"20px"}
          size={"lg"}
          bg={"#B889FF"}
          onClick={title === "Stake" ? handleStake : handleUnstake}
        >
          {title.toUpperCase()}
        </Button>
    </Container>
  );
}

export default function Stats({ walletAddress, setPassesStaked, 
passesStaked, passesOwned }) {
  return (
    walletAddress.length > 0 && (
      <Box
        maxW="7xl"
        mx={"auto"}
        mt={10}
        pt={5}
        px={{ base: 2, sm: 12, md: 17 }}
      >
        <Heading fontFamily={"Lato"} size={"lg"} textAlign={"left"} mb={6}>
          Staking
        </Heading>
        <SimpleGrid
          minChildWidth={510}
          columns={{ base: 1, md: 2 }}
          spacing="8"
        >
          <StakingCard
            title={"Stake"}
            background={"rgba(255, 255, 255, 0.04)"}
            walletAddress={walletAddress}
            setPassesStaked={setPassesStaked}
            passesOwned={passesOwned}
          />
          <StakingCard
            title={"Unstake"}
            background={"rgba(255, 255, 255, 0.04)"}
            walletAddress={walletAddress}
            setPassesStaked={setPassesStaked}
            passesStaked={passesStaked}
          />
        </SimpleGrid>
      </Box>
    )
  );
}
