import React from "react";
import { Box } from "@chakra-ui/react";
import Hero from "../components/Hero";
import Navbar from "../components/NavBar";
import Features from "../components/Features";
import Profiles from "../components/Profiles";
import Footer from "../components/Footer";

function Main() {
  return (
    <Box
      textAlign="center"
      fontSize="xl"
      style={{
        backgroundColor: "#11182b",
      }}
    >
      <Navbar />
      <Hero />
      <Features />
      <Profiles />
      <Footer />
    </Box>
  );
}

export default Main;
