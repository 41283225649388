
import React from 'react';
import {
    Box,
    Heading,
    Text
  } from "@chakra-ui/react";
  
function PrivacyPolicy () {
    return (
    <Box background="white" p="3" pb="400" width="100%" >
        <Heading as="h1" size={"2xl"} color="black" mb={"3"}>
            Privacy Policy
        </Heading>
        <Heading as="h2" size={"lg"} color="black" my="2">
           What data do we collect? 
        </Heading>
            <Text color={"black"} ml="2"> 
                The AquaTools extension does not collect any personal data. The tool utilizes chrome storage to store the user's tool preferences, opensea collections the that user decides to save, and optionally, the user's discord webhook.
            </Text>
        <Heading as="h2" size={"lg"} color="black" my="2"> 
            How do you use the data you collect?
        </Heading>
            <Text color={"black"} ml="2"> 
            The AquaTools extension utilizes user preferences to customize the display, function and exection of the extension's injected features on the opensea website. The extension uses the user's discord webhook to send a webhook when a listing matches the user's set preferences.
            </Text>        
        <Heading as="h2" size={"lg"} color="black" my="2">
            Do you share or sell the data you have collected?
        </Heading>
            <Text color={"black"} ml="2"> 
            The AquaTools extension does not collect any personal data, nor does it store any user data outside of chrome storage. The AquaTools extension does not share or sell any user data.
            </Text>
        <Heading as="h2" size={"lg"} color="black" my="2">
            How do you secure the data you collect?
        </Heading>
            <Text color={"black"} ml="2"> 
            The AquaTools extension does not collect any personal data, nor does it store any user data outside of chrome storage.
            </Text>
    </Box>
    );
}
export default PrivacyPolicy;