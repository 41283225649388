import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Image,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";

import { ethers } from "ethers";
import AquaTools from "../artifacts/contracts/AquaTools.sol/AquaTools.json";
import axios from "axios";

export default function Mint() {
  const [amount, setAmount] = useState(1);
  const [supplyLeft, setSupplyLeft] = useState(0);
  const toast = useToast();

  const contractAddress = "0x76f3A1eF80a1142f9bD2b868d59Ab75a1c0916d4";
  const saleType = "public";

  const mint = async () => {
    if (!window.ethereum) {
      toast({
        title: "Error",
        description: "Metamask not found.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    if (window.ethereum.chainId !== "0x1") {
      toast({
        title: "Error",
        description: "Please connect to mainnet.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    await window.ethereum.request({ method: "eth_requestAccounts" });

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let address;

    try {
      address = await signer.getAddress();
    } catch (err) {
      toast({
        title: "Error",
        description: "Metamask not connected.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    const contract = new ethers.Contract(
      contractAddress,
      AquaTools.abi,
      signer
    );

    const price = 0.08;
    const totalCost = price * amount;

    if (saleType === "public") {
      // Normal Mint
      try {
        await contract.mint(address, amount, {
          value: ethers.utils.parseEther(totalCost.toString()),
        });
      } catch (e) {
        const errMsg = e.message.match(/[^:KEY"]+(?="})/)[0];
        toast({
          title: "Error",
          description: errMsg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });

        return;
      }
    } else {
      // WL Mint
      // Get merkle proof
      const res = await axios.get(
        process.env.NODE_ENV === "development"
          ? `http://localhost:4000/api/merkleProof/${address}`
          : `https://api.aquatools-nft.com/api/merkleProof/${address}`
      );
      const proof = res.data.proof;

      try {
        await contract.mintPresale(address, amount, proof, {
          value: ethers.utils.parseEther(totalCost.toString()),
        });
      } catch (e) {
        const errMsg = e.message.match(/[^:KEY"]+(?="})/)[0];
        toast({
          title: "Error",
          description: errMsg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const getCount = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      AquaTools.abi,
      signer
    );
    const count = (await contract.getSupplyLeft()) - 1500;
    setSupplyLeft(parseInt(count));
  };

  useEffect(() => {
    getCount();
    const interval = setInterval(() => getCount(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      position={"relative"}
      mt={{ base: 5, sm: 10, lg: 20 }}
      mb={{ base: 5, sm: 10, lg: 20 }}
    >
      <Heading
        color={"white.800"}
        lineHeight={1.1}
        fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        mb={{ base: 5, sm: 10, lg: 20 }}
      >
        <Text
          as={"span"}
          bgGradient="linear(to-r, blue.400,purple.400)"
          bgClip="text"
        >
          {`${supplyLeft} Passes Left`}
        </Text>
      </Heading>

      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 15 }}
      >
        <Stack p={{ base: 4, sm: 6, md: 8, lg: 10 }}>
          <Image src="/pass.png"></Image>
        </Stack>

        <Stack
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8, lg: 10 }}
          m={{ base: 4, sm: 6, md: 8, lg: 10 }}
          spacing={{ base: 8 }}
          bg={"#dbdbdb0a"}
        >
          <Stack spacing={4}>
            <Heading
              color={"white.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              Mint Pass
            </Heading>
          </Stack>
          <Box as={"form"} mt={10}>
            <Stack
              spacing={4}
              alignContent={"center"}
              alignItems={"center"}
              align={"center"}
            >
              <NumberInput
                onChange={(valueString) => setAmount(valueString)}
                defaultValue={1}
                step={1}
                min={1}
                max={saleType === "public" ? 3 : 1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <Button
                fontFamily={"heading"}
                mt={8}
                w={"30"}
                bgGradient="linear(to-r, red.400,pink.400)"
                style={{
                  background:
                    "linear-gradient(271.13deg, #b889ff -18.58%, #4a80e2 120.87%)",
                }}
                _hover={{
                  bgGradient: "linear(to-r, red.400,pink.400)",
                  boxShadow: "xl",
                }}
                onClick={() => mint()}
                disabled={supplyLeft <= 0}
              >
                Mint
              </Button>
            </Stack>
          </Box>
          form
        </Stack>
      </Container>
    </Box>
  );
}
