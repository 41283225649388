import React, { useState, useEffect } from "react";
import { Box, useToast, Tabs, TabList, Tab, TabPanels, TabPanel, Heading } from "@chakra-ui/react";
import { gql } from "@apollo/client";
import { ethers } from "ethers";

import ShopStats from "../components/ShopStats";
import Staking from "../components/Staking";
import ShopItems from "../components/ShopItems";
import PurchasedItems from "../components/PurchasedItems";
import AccountBar from "../components/AccountBar";

import AquaStaking from "../artifacts/contracts/AquaStaking.sol/AquaStaking.json";
import AquaTools from "../artifacts/contracts/AquaTools.sol/AquaTools.json"


// GQL QUERIES AND MUTATIONS
const GET_USER = gql`
  query getUser($id: ID!) {
    user(ID: $id) {
      _id
      discordID
      whitelistAddress
      passesOwned
      tokensOwned
      role
    }
  }
`;

const CREATE_USER = gql`
  mutation Mutation($createUserInput: CreateUserInput) {
    createUser(createUserInput: $createUserInput) {
      _id
      discordID
      whitelistAddress
      passesOwned
      tokensOwned
      role
    }
  }
`;

function ShopPage({ client }) {
  const [walletAddress, setWallet] = useState(
    localStorage.getItem("walletAddress") || ""
  );
  const [passesStaked, setPassesStaked] = useState(0);
  const [passesOwned, setPassesOwned] = useState(0);

  const toast = useToast();

  const stakingContractAddress = "0xD96A3c1FAF50832E09a465C5c202FC19d5C8aC45";
  const passesContractAddress = "0x76f3a1ef80a1142f9bd2b868d59ab75a1c0916d4"
  var provider, signer, contract, passContract;
  if(window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner();

    contract = new ethers.Contract(
      stakingContractAddress,
      AquaStaking.abi,
      signer
    );

    passContract = new ethers.Contract(
      passesContractAddress,
      AquaTools.abi,
      signer
    );
  }

  const getPassesStaked = async () => {
    const amt = await contract.amountStaked(walletAddress);
    setPassesStaked(parseInt(amt));
  };

  const getPassesOwned = async () => {
    const amt = await passContract.balanceOf(walletAddress, 0);
    setPassesOwned(parseInt(amt));
  };

  useEffect(() => {
    const updateAddress = async () => {
      localStorage.setItem("walletAddress", walletAddress);
      await getPassesStaked();
      await getPassesOwned();
    }

    updateAddress();
  }, [walletAddress]);

  useEffect(() => {
    const refreshStaking = async () => {
      await getPassesStaked();
      await getPassesOwned();
    }

    document.addEventListener("stakingRefresh", refreshStaking)  
    return () => document.removeEventListener("stakingRefresh", refreshStaking);
  });

  useEffect(() => {
    const fetchData =  async () => {
      if (walletAddress.length > 0) {
        const res = await client.query({
          query: GET_USER,
          variables: {
            id: ethers.utils.getAddress(walletAddress),
          },
        });
        if (res.data.user === null) {
          let createErrMessage = (
            await client.mutate({
              mutation: CREATE_USER,
              variables: {
                createUserInput: {
                  address: ethers.utils.getAddress(walletAddress),
                },
              },
            })
          ).data.createUser;
          toast({
            title: "Success",
            description: `New Wallet ${createErrMessage._id}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          const event = new Event("statsRefresh");
          document.dispatchEvent(event);
        }
      }
    }
    fetchData();
  }, [walletAddress, client, toast]);

  const handleTabChange = async (index)=> {
    if(index) {
      const event = new Event("transactionsRefresh");
      document.dispatchEvent(event);
    }else {
      const event = new Event("itemRefresh");
      document.dispatchEvent(event);
    }
  }

  return (
    <Box
      textAlign="center"
      fontSize="xl"
      style={{
        backgroundColor: "#11182b",
      }}
    >
      <AccountBar
        setWallet={setWallet}
        walletAddress={walletAddress}
        client={client}
        passesOwned={passesOwned}
      />
      <ShopStats 
        walletAddress={walletAddress} 
        passesStaked={passesStaked} 
        passesOwned={passesOwned}
      />
      <Staking
        walletAddress={walletAddress}
        setPassesStaked={setPassesStaked}
        passesStaked={passesStaked}
        passesOwned={passesOwned}
      />
      {walletAddress.length > 0? 
      <Tabs 
        isFitted size="lg" maxW="7xl" mx={"auto"} mt={10} pt={5}
        px={{ base: 2, sm: 12, md: 17 }}
        onChange={handleTabChange}
      >
        <TabList fontFamily={"Lato"}>
          <Tab 
            _selected={{ boxShadow:"none", borderColor:"#63b3ed"}} 
            onClick={() => {
              const event = new Event("itemRefresh");
              document.dispatchEvent(event);
            }}
          >
            Marketplace
          </Tab>
          <Tab _selected={{ boxShadow:"none", borderColor:"#63b3ed"}} >
            Purchased Items
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ShopItems walletAddress={walletAddress} client={client} />
          </TabPanel>
          <TabPanel>
            <PurchasedItems walletAddress={walletAddress} client={client} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      :
      <Heading mt="10" as="h2" size="xl">
        Please press connect wallet to enter the Aqua Shop.
      </Heading>
    }
    </Box>
  );
}

export default ShopPage;
